import React, { useState,useEffect } from 'react';
import { 
  Flex,useToast
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SearchTablePatients from "views/admin/patients/overview/components/SearchTablePatientsOverivew";
import { columnsDataPatientsOverview } from "views/admin/patients/overview/variables/columnsDataPatientsOverview";
import { API_BASE_URL } from "constants/apiConstants";
import axios from "axios";

export default function PatientOverview() {
  const [patients,setPatients] = useState([]);
  const toast = useToast()
  const fetchPatient = ()  => {
    axios.get(API_BASE_URL+'patient/overview',{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
      })
    .then((response) => {
      setPatients(response.data.data)
    })
    .catch(function (error){
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  useEffect(()=> {
    fetchPatient()
  },[]);

    return (
      <>
      <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
        <Card px='0px'>
          <SearchTablePatients
            tableData={patients}
            columnsData={columnsDataPatientsOverview}
          />
        </Card>
      </Flex>
      </>
    );
}
        
export const columnsDataMedicalRecordOverview = [
    {
      Header: "ACTIONS",
      accessor: "id",
    },
    {
      Header: "Hospital Name",
      accessor: "hospital_name",
    },
    {
      Header: "Hospital Address",
      accessor: "hospital_location",
    },
    {
      Header: "Medical Report",
      accessor: "mediacalreportid",
    },
    {
      Header: "Created Date",
      accessor: "date",
    }
  ];
  
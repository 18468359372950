import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    FormLabel,
    useToast
  } from '@chakra-ui/react';
  // Custom components
  
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';
import { useParams } from 'react-router-dom';
import { ASSETS_URL } from 'constants/apiConstants';
  
export default function Edit() {
    const navigate = useNavigate();
    const { id } = useParams();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        user: false,
    });
    
    const [input,setInput] = useState({
        name: "",
        username: "",
        address: "",
        email_id: "",
        mobile_number: ""
    });
    const [errors, setErrors] = useState({});
    const toast = useToast();
    const [hospitalId, setHospitalId] = useState('');
    const [profileImage,setProfileImage] = useState(null);
    const [profilePreview,setProfilePreview] = useState(null);

    const getHospital = (e) => {
        axios.get(API_BASE_URL+'hospital/'+id,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
        })
        .then(function (response){
            setHospitalId(response.data.data.id);
            setInput({
              name: response.data.data.name,
              username: response.data.data.user_name,
              mobile_number: response.data.data.phone_number,
              email: response.data.data.email,
              address: response.data.data.address,
            });
            if(response.data.data.profile_picture){
              setProfilePreview(ASSETS_URL+'users/profile/original/'+response.data.data.profile_picture);
            }else{
              setProfilePreview('');
            }
        })
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });

        if((name === 'username' || name === 'email') && value != ''){
            const payload = {
            "name":name,
            "value":value,
            "hospitalId": hospitalId
            }
            axios.post(API_BASE_URL+'check/value',payload,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
            })
            .then(function (response){
            if(response.data.status === false){
                toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
                if(name === 'username'){
                setInput({
                    'username': '',
                });
                }else if(name === 'email'){
                setInput({
                    'email': '',
                });
                }
            }
            })
            .catch(function (error){
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
            })
        }
    };

    const validateForm = (data) => {
        const errors = {};
        
        if (!data.name) {
            errors.name = 'Name field is required';
        }

        if (!data.username) {
            errors.username = 'Username field is required';
        }
    
        if (!data.mobile_number) {
            errors.mobile_number = 'Mobile number field is required';
        }
    
        if (!data.email) {
            errors.email = 'Email field is required';
        }
    
        return errors;
    };

    const handleSubmitPersonalDetailsInfo = (e) => {
        e.preventDefault();
        const newErrors = validateForm(input);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const payload = {
                "name":input.name,
                "username":input.username,
                "address":input.address,
                "email":input.email,
                "mobile_number":input.mobile_number,
                "profileImage": profileImage,
                "hospitalId": hospitalId
            };

            axios.post(API_BASE_URL+'hospital/store-update',payload,{
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
                }
            })
            .then(function (response){
                if(response.data.status === true){
                    setHospitalId(response.data.data.hospitalId)
                    toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
                }else if(response.data.status === false){
                    toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
                }
                navigate("/admin/hospital-management/hospital/overview");
            })
            .catch(function (error){
                toast({ title: error,status: 'error',isClosable: true,position: 'top'})
            })
        }
    };

    const handleProfileImageUpload = (acceptedFiles) => {
        setProfilePreview(acceptedFiles.map(file => URL.createObjectURL(file)));
        setProfileImage(acceptedFiles[0]);
    }
    
    const removeImage = () => {
        if(hospitalId){
            const payload = {
                'hospitalId': hospitalId,
            }
            axios.post(API_BASE_URL+'hospital/delete/profile-image',payload,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
                }
            })
            .then(function (response){
                if(response.data.status === true){
                    setProfilePreview(null);
                }else if(response.data.status === false){
                    toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
                }
            })
            .catch((error) => {
                toast({ title: error,status: 'error',isClosable: true,position: 'top'})
            })
        }else{
            setProfilePreview(null);
        }
    };

    useEffect(() => {
        getHospital();
    },[]);
    
        const userTab = React.useRef();
    
        return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{ sm: '125px', lg: '75px' }}
            position="relative"
            >
            <Box
                h="45vh"
                bgGradient="linear(to-b, brand.400, brand.600)"
                position="absolute"
                w="100%"
                borderRadius="20px"
            />
        
            <Tabs
                variant="unstyled"
                zIndex="0"
                mt={{ base: '60px', md: '165px' }}
                display="flex"
                flexDirection="column"
            >
                <TabList
                display="flex"
                alignItems="center"
                alignSelf="center"
                justifySelf="center"
                >
                <Tab
                    _focus={{ border: '0px', boxShadow: 'unset' }}
                    ref={userTab}
                    w={{ sm: '120px', md: '250px', lg: '300px' }}
                >
                    <Flex
                    direction="column"
                    justify="center"
                    align="center"
                    position="relative"
                    _before={{
                        content: "''",
                        width: { sm: '120px', md: '250px', lg: '300px' },
                        height: '3px',
                        bg: activeBullets.user ? 'white' : 'brand.400',
                        left: { sm: '12px', md: '30px' },
                        top: {
                        sm: activeBullets.user ? '6px' : '4px',
                        md: null,
                        },
                        position: 'absolute',
                        bottom: activeBullets.user ? '40px' : '38px',
                        transition: 'all .3s ease',
                    }}
                    >
                    <Box
                        zIndex="1"
                        border="2px solid"
                        borderColor={activeBullets.user ? 'white' : 'brand.400'}
                        bgGradient="linear(to-b, brand.400, brand.600)"
                        w="16px"
                        h="16px"
                        mb="8px"
                        borderRadius="50%"
                    />
                    <Text
                        color={activeBullets.user ? 'white' : 'gray.300'}
                        fontWeight={activeBullets.user ? 'bold' : 'normal'}
                        display={{ sm: 'none', md: 'block' }}
                    >
                        Add Hospital
                    </Text>
                    </Flex>
                </Tab>
    
                </TabList>
    
                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                <TabPanel
                    w={{ sm: '330px', md: '700px', lg: '850px' }}
                    p="0px"
                    mx="auto"
                >
                    <Card p="30px">
                    <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                        Hospital Details
                    </Text>
                    <form onSubmit={handleSubmitPersonalDetailsInfo}>
                        <Flex direction="column" w="100%">
    
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                            <FormLabel
                                ms="10px"
                                htmlFor="property_logo"
                                fontSize="sm"
                                color=''
                                fontWeight="bold"
                                _hover={{ cursor: 'pointer' }}
                            >
                                Profile Image
                            </FormLabel>
    
                                <Dropzone onDrop={handleProfileImageUpload} accept="image/*" maxFiles={1}>
                                    {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";

                                        return (
                                        <div
                                        {...getRootProps({
                                            className: `dropzone ${additionalClass}`
                                        })}
                                        >
                                            <input {...getInputProps()} />
                                            <p>Drag'n'drop images,or click to select files</p>
                                        </div>
                                        );
                                    }}
                                </Dropzone>

                                {profilePreview && (
                                    <div className='new_box'>
                                    <img src={profilePreview} className='img-container logo-image' alt='upload cover file' width="50%"/>
                                    <button type='button' className='remove-image' onClick={() => removeImage(profilePreview)}>X</button>
                                    </div>
                                )}
                            </Flex>
                            </Stack>
    
                            <Stack direction="column" spacing="20px">
                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="name"
                                        placeholder="eg. JIMIN GABRIEL"
                                        label="Name *"
                                        name="name"
                                        onChange={handleChange}
                                        value={input.name}
                                    />
                                    {errors.name &&
                                    <span className="error-message">
                                        {errors.name}
                                    </span>
                                    }
                                </Flex>
                                </Stack>
    
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="username"
                                        placeholder="Username"
                                        label="User Name *"
                                        name="username"
                                        onChange={handleChange}
                                        value={input.username}
                                    />
                                    {errors.username &&
                                    <span className="error-message">
                                        {errors.username}
                                    </span>
                                    }
                                </Flex>
                                </Stack>
    
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="address"
                                        placeholder="eg. BUSINESS BAY, DUBAI, UAE"
                                        label="Address"
                                        name="address"
                                        onChange={handleChange}
                                        value={input.address}
                                    />
                                    {errors.address &&
                                    <span className="error-message">
                                        {errors.address}
                                    </span>
                                    }
                                </Flex>
                                </Stack>
    
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="mobile_number"
                                        placeholder="eg. 1234567890"
                                        label="Mobile Number *"
                                        name="mobile_number"
                                        onChange={handleChange}
                                        value={input.mobile_number}
                                    />
                                    {errors.mobile_number &&
                                    <span className="error-message">
                                        {errors.mobile_number}
                                    </span>
                                    }
                                </Flex>
                                </Stack>
    
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="email"
                                        placeholder="jimingabriel123@gmail.com"
                                        label="Email *"
                                        name="email"
                                        onChange={handleChange}
                                        value={input.email}
                                    />
                                    {errors.email &&
                                    <span className="error-message">
                                        {errors.email}
                                    </span>
                                    }
                                </Flex>
                                </Stack>
                            </SimpleGrid>
                            </Stack>
    
                            <Flex justify="space-between" mt="24px">
                            <Button
                                variant="darkBrand"
                                fontSize="sm"
                                borderRadius="16px"
                                w={{ base: '128px', md: '148px' }}
                                h="46px"
                                ms="auto"
                                type='submit'
                                // onClick={() => addressTab.current.click()}
                            >
                                Next
                            </Button>
                            </Flex>
                        </Flex>
                    </form>
                    </Card>
                </TabPanel>
                </TabPanels>
            </Tabs>
            </Flex>
        );
}
  
export const columnsDataDoctorsOverview = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "USERNAME",
    accessor: "user_name",
  },
  {
    Header: "Mobile Number",
    accessor: "phone_number",
  },
  {
    Header: "JOIN DATE",
    accessor: "date",
  },
  {
    Header: "Device Type",
    accessor: "device_type",
  },
  {
    Header: "Account Type",
    accessor: "account_type",
  },
  {
    Header: "ACTIONS",
    accessor: "id",
  },
];

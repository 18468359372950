import React, { useState,useEffect } from 'react';
import { 
  Flex,useToast
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SearchTableMedicalRecord from "views/admin/patients/medicalRecord/overview/components/SearchTableMedicalRecordOverivew";
import { columnsDataMedicalRecordOverview } from "views/admin/patients/medicalRecord/overview/variables/columnsDataMedicalRecordOverview";
import { API_BASE_URL } from "constants/apiConstants";
import axios from "axios";
import { useParams } from 'react-router-dom';

export default function MedicalRecordOverview() {
  const [patients,setPatients] = useState([]);
  const { id } = useParams();
  const toast = useToast()
  const fetchPatient = ()  => {
    axios.get(API_BASE_URL+'patient/medical-records/'+id,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
      })
    .then((response) => {
      setPatients(response.data.data)
    })
    .catch(function (error){
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  useEffect(()=> {
    fetchPatient()
  },[]);

  return (
    <>
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
        <SearchTableMedicalRecord
          tableData={patients}
          columnsData={columnsDataMedicalRecordOverview}
        />
      </Card>
    </Flex>
    </>
  );
}
        
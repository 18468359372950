export const columnsDataHospitalsOverview = [
    {
      Header: "ACTIONS",
      accessor: "id",
    },
    {
        Header: "Image",
        accessor: "profile_picture",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "USERNAME",
      accessor: "user_name",
    },
    {
      Header: "Mobile Number",
      accessor: "phone_number",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "JOIN DATE",
      accessor: "date",
    },
  ];
  
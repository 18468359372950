import React, { useState,useEffect } from 'react';
import { 
  Flex,useToast
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SearchTableDoctors from "views/admin/doctors/overview/components/SearchTableDoctorsOverivew";
import { columnsDataDoctorsOverview } from "views/admin/doctors/overview/variables/columnsDataDoctorsOverview";
import { API_BASE_URL } from "constants/apiConstants";
import axios from "axios";

export default function DoctorOverview() {
  const [doctors,setDoctors] = useState([]); 
  const toast = useToast()
  const fetchDoctors = ()  => {
    axios.get(API_BASE_URL+'doctor/overview',{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
      })
    .then((response) => {
      setDoctors(response.data.data)
    })
    .catch(function (error){
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  useEffect(()=> {
    fetchDoctors()
  },[]);

  return (
    <>
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
        <SearchTableDoctors
          tableData={doctors}
          columnsData={columnsDataDoctorsOverview}
        />
      </Card>
    </Flex>
    </>
  );
}
        
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    FormLabel,
    useToast,
    RadioGroup,
    Radio
  } from '@chakra-ui/react';
  // Custom components
  
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import React, { useState,useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import Dropzone from 'react-dropzone';
  import Multiselect from 'multiselect-react-dropdown';
  import axios from 'axios';
  import { API_BASE_URL } from 'constants/apiConstants';
  
  export default function Edit() {
    const navigate = useNavigate();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [medicalConditions,setMedicalConditions] =useState([]);
    const [activeBullets, setActiveBullets] = useState({
      user: true,
      educationalInformation: false,
      professionalInformation: false,
      publicationResearch: false,
      accessibility: false,
    });
    
    const [input,setInput] = useState({
        hospital_name: "",
        patient_id: "",
        blood_group: "",
        doctor: "",
        gender: "",
        weight: "",
        height: "",
        medical_conditions_ids: "",
    });
    const [errors, setErrors] = useState({});
    const toast = useToast();
    const [doctorId, setDoctorId] = useState('');
    const [gender, setGender] = useState('');
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInput({
        ...input,
        [name]: value,
      });
    };
  
    const handleGenderChange = (value) => {
      setGender(value);
    };

    const getMedicalConditions = () => {
        axios.get(API_BASE_URL+'medical/conditions',{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then((response) => {
            setMedicalConditions(response.data.data)
        })
        .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }

    const handleMedicalConditionsSelectValue = (value) => {
        input.medical_conditions_ids = [];
        value.forEach(function(v) {
          input.medical_conditions_ids.push(v.id);
        });
    };
  
    const validateForm = (data) => {
      const errors = {};
      
      if (!data.name) {
        errors.name = 'Name field is required';
      }
  
      if (!data.patient_id) {
        errors.patient_id = 'Patient id field is required';
      }
      
      if (!data.address) {
        errors.address = 'Address field is required';
      }
  
      if (!data.mobile_number) {
        errors.mobile_number = 'Mobile number field is required';
      }
  
      if (!data.email) {
        errors.email = 'Email field is required';
      }
  
      if (!data.pay_type) {
        errors.pay_type = 'Pay type field is required';
      }
      return errors;
    };
  
    const handleSubmitPersonalDetailsInfo = (e) => {
      e.preventDefault();
      const newErrors = validateForm(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "first_name":input.first_name,
          "last_name":input.last_name,
          "username":input.username,
          "email":input.email,
          "gender":gender,
          "mobile_number":input.mobile_number,
          "passcode":input.passcode,
          "date_of_birth":input.date_of_birth,
          "place_of_birth": input.place_of_birth,
          "nationality": input.nationality
        };
        setDoctorId(1);
        educationalInformationTab.current.click();
      }
    };
  
    const validateStep2InformationForm = (data) => {
      const errors = {};
      
      if (!data.insurance) {
        errors.insurance = 'Insurance field is required';
      }
  
      if (!data.insurance_id) {
        errors.insurance_id = 'Insurance id field is required';
      }
      
      if (!data.coverage) {
        errors.coverage = 'Coverage field is required';
      }
  
      if (!data.allergies) {
        errors.allergies = 'Allergies field is required';
      }
  
      if (!data.x_ray) {
        errors.x_ray = 'X-ray field is required';
      }
      return errors;
    };
  
    const handleSubmitStep2Info = (e) => {
      
      e.preventDefault();
  
      if(doctorId === ''){
        toast({ title: 'Please fill Step 1 details first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateStep2InformationForm(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        professionalInformationTab.current.click();
      }
    };
  
    const validateStep3Form = (data) => {
      const errors = {};
      
      if (!data.weight) {
        errors.weight = 'Weight field is required';
      }
  
      if (!data.height) {
        errors.height = 'Height field is required';
      }
      
      if (!data.blood_presure) {
        errors.blood_presure = 'Blood presure field is required';
      }
  
      if (!data.pulse) {
        errors.pulse = 'Pulse field is required';
      }
      return errors;
    };
  
    const handleSubmitStep3Info = (e) => {
      e.preventDefault();
  
      if(doctorId === ''){
        toast({ title: 'Please fill step 2 first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateStep3Form(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "medical_degree":input.medical_degree,
          "last_nmedical_schoolame":input.medical_school,
          "year_of_graduation":input.year_of_graduation,
          "specialisation":input.specialisation,
          "board_certifications":input.board_certifications
        }
        publicationResearchTab.current.click();
      }
    };
  
    const validateSubmitStep4Form = (data) => {
      const errors = {};
      
      if (!data.wbc) {
        errors.wbc = 'WBC field is required';
      }
  
      if (!data.neu) {
        errors.neu = 'NEU field is required';
      }
  
      if (!data.lym) {
        errors.lym = 'LYM field is required';
      }
  
      if (!data.mono) {
        errors.mono = 'MONO field is required';
      }
  
      if (!data.reason_of_visit) {
        errors.reason_of_visit = 'Reason of visit field is required';
      }
  
      if (!data.diagnosis) {
        errors.diagnosis = 'Diagnosis field is required';
      }
  
      if (!data.medications) {
        errors.medications = 'Medications field is required';
      }
      return errors;
    };
  
    const handleSubmitStep4Info = (e) => {
      e.preventDefault();
  
      if(doctorId === ''){
        toast({ title: 'Please fill professional information first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateSubmitStep4Form(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "publications":input.publications,
          "research_interests":input.research_interests
        }
        accessibilityTab.current.click();
      }
    };
  
    const validateStep5Form = (data) => {
      const errors = {};
      
      if (!data.details_of_visit) {
        errors.details_of_visit = 'Details of visit field is required';
      }
  
      if (!data.referrals) {
        errors.referrals = 'Referrals field is required';
      }
      return errors;
    };
  
    const handleSubmitStep5Info = (e) => {
      e.preventDefault();
  
      if(doctorId === ''){
        toast({ title: 'Please fill publications & research first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateStep5Form(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        navigate("/admin/health-records/dashboards/default");
      }
    };

    useEffect(() => {
        getMedicalConditions();
    },[])
  
    const userTab = React.useRef();
    const educationalInformationTab = React.useRef();
    const professionalInformationTab = React.useRef();
    const publicationResearchTab = React.useRef();
    const accessibilityTab = React.useRef();
  
    return (
      <Flex
          direction="column"
          minH="100vh"
          align="center"
          pt={{ sm: '125px', lg: '75px' }}
          position="relative"
        >
          <Box
            h="45vh"
            bgGradient="linear(to-b, brand.400, brand.600)"
            position="absolute"
            w="100%"
            borderRadius="20px"
          />
    
          <Tabs
            variant="unstyled"
            zIndex="0"
            mt={{ base: '60px', md: '165px' }}
            display="flex"
            flexDirection="column"
          >
            <TabList
              display="flex"
              alignItems="center"
              alignSelf="center"
              justifySelf="center"
            >
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={userTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: false,
                    professionalInformation: false,
                    publicationResearch: false,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.user ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.user ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.user ? '40px' : '38px',
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.user ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.user ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.user ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 1
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={educationalInformationTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: false,
                    publicationResearch: false,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.educationalInformation ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.educationalInformation ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.educationalInformation ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.educationalInformation ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.educationalInformation ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.educationalInformation ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 2
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={professionalInformationTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: true,
                    publicationResearch: false,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.professionalInformation ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.professionalInformation ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.professionalInformation ? '40px' : '38px',
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.professionalInformation ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.professionalInformation ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.professionalInformation ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 3
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={publicationResearchTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: true,
                    publicationResearch: true,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.publicationResearch ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.publicationResearch ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.publicationResearch ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.publicationResearch ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.publicationResearch ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.publicationResearch ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 4
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={accessibilityTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: true,
                    publicationResearch: true,
                    accessibility: true,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.accessibility ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '32px' },
                    top: '6px',
                    position: 'absolute',
                    bottom: activeBullets.accessibility ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.accessibility ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.accessibility ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.accessibility ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 5
                  </Text>
                </Flex>
              </Tab>
  
            </TabList>
  
            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    Personal Details
                  </Text>
                  <form onSubmit={handleSubmitPersonalDetailsInfo}>
                    <Flex direction="column" w="100%">

                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="hospital_name"
                                    placeholder="eg. Hospital Name"
                                    label="Hospital Name"
                                    name="hospital_name"
                                    onChange={handleChange}
                                    value={input.hospital_name}
                                />
                                {errors.hospital_name &&
                                  <span className="error-message">
                                      {errors.hospital_name}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="patient_id"
                                    placeholder="eg. PID123456789"
                                    label="Patient Id"
                                    name="patient_id"
                                    onChange={handleChange}
                                    value={input.patient_id}
                                />
                                {errors.patient_id &&
                                  <span className="error-message">
                                      {errors.patient_id}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="blood_group"
                                    placeholder="eg. A+"
                                    label="Blood Group"
                                    name="blood_group"
                                    onChange={handleChange}
                                    value={input.blood_group}
                                />
                                {errors.blood_group &&
                                  <span className="error-message">
                                      {errors.blood_group}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="doctor"
                                    placeholder="eg. Doctor"
                                    label="Doctor"
                                    name="doctor"
                                    onChange={handleChange}
                                    value={input.doctor}
                                />
                                {errors.doctor &&
                                  <span className="error-message">
                                      {errors.doctor}
                                  </span>
                                }
                              </Flex>
                            </Stack>

                            <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="gender"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Gender
                                  </FormLabel>

                                  <RadioGroup onChange={handleGenderChange} value={gender} name='gender'>
                                    <Stack direction='row'>
                                      <Radio value='1'>Male</Radio>
                                      <Radio value='2'>Female</Radio>
                                    </Stack>
                                  </RadioGroup>
                                  {errors.gender &&
                                    <span className="error-message">
                                        {errors.gender}
                                    </span>
                                  }
                                </Flex>
                              </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="weight"
                                    placeholder="74 kg"
                                    label="Weigth in kg"
                                    name="weight"
                                    onChange={handleChange}
                                    value={input.weight}
                                />
                                {errors.weight &&
                                  <span className="error-message">
                                      {errors.weight}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="height"
                                      placeholder="ONLINE"
                                      label="Height in cm"
                                      name="height"
                                      onChange={handleChange}
                                      value={input.height}
                                  />
                                  {errors.height &&
                                    <span className="error-message">
                                        {errors.height}
                                    </span>
                                  }
                                </Flex>
                            </Stack>

                            <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                    <FormLabel
                                    ms="10px"
                                    htmlFor="standout-amenity"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                    >
                                    Do you have any previous medical conditions ?
                                    </FormLabel>

                                    <Multiselect
                                        options={medicalConditions} // Options to display in the dropdown
                                        onSelect={handleMedicalConditionsSelectValue} // Function will trigger on select event
                                        onRemove={handleMedicalConditionsSelectValue} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                        borderRadius="16px"
                                    />

                                    {/* <Select
                                    id="standout-amenity"
                                    value={input.standout_amenity_ids}
                                    name="standout_amenity_ids"
                                    onChange={handleChange}
                                    borderRadius="16px"
                                    >
                                    <option value=''>Select...</option>
                                    {standoutAmenities.map((option) => (
                                        <option value={option.value}>{option.label}</option >
                                    ))}
                                    </Select> */}
                                    {errors.standout_amenity_ids &&
                                    <span className="error-message">
                                        {errors.standout_amenity_ids}
                                    </span>
                                    }
                                </Flex>
                            </Stack>

                          </SimpleGrid>
                        </Stack>
  
                        <Flex justify="space-between" mt="24px">
                          <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            // onClick={() => addressTab.current.click()}
                          >
                            Next
                          </Button>
                        </Flex>
                    </Flex>
                  </form>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    Insurance Information
                  </Text>
                  <form onSubmit={handleSubmitStep2Info}>
                    <Flex direction="column" w="100%">
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="insurance"
                                    placeholder="eg. ABU DHABI NATIONAL INSURANCE"
                                    label="Insurance"
                                    name="insurance"
                                    onChange={handleChange}
                                    value={input.Insurance}
                                />
                                {errors.insurance &&
                                  <span className="error-message">
                                      {errors.insurance}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="insurance_id"
                                    placeholder="eg. 01234567789"
                                    label="Insured ID"
                                    name="insurance_id"
                                    onChange={handleChange}
                                    value={input.insurance_id}
                                />
                                {errors.insurance_id &&
                                  <span className="error-message">
                                      {errors.insurance_id}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="coverage"
                                    placeholder="4030120241"
                                    label="Coverage"
                                    name="coverage"
                                    onChange={handleChange}
                                    value={input.coverage}
                                />
                                {errors.coverage &&
                                  <span className="error-message">
                                      {errors.coverage}
                                  </span>
                                }
                              </Flex>
                            </Stack>
                          </SimpleGrid>
                        </Stack>
  
                        <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                          Medical History
                        </Text>
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="property_logo"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            Surgeries
                          </FormLabel>
  
                          <Dropzone onDrop='' accept="image/*" maxFiles={1}>
                            {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                              const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";
  
                              return (
                                <div
                                {...getRootProps({
                                  className: `dropzone ${additionalClass}`
                                })}
                                >
                                  <input {...getInputProps()} />
                                  <p>Drag'n'drop images,or click to select files</p>
                                </div>
                              );
                            }}
                          </Dropzone>
                          </Flex>
                        </Stack>
  
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="allergies"
                                    placeholder="eg. FOOD ALLERGIES"
                                    label="Allergies"
                                    name="allergies"
                                    onChange={handleChange}
                                    value={input.allergies}
                                />
                                {errors.allergies &&
                                  <span className="error-message">
                                      {errors.allergies}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="x_ray"
                                    label="X-rays"
                                    name="x_ray"
                                    onChange={handleChange}
                                    value={input.x_ray}
                                />
                                {errors.x_ray &&
                                  <span className="error-message">
                                      {errors.x_ray}
                                  </span>
                                }
                              </Flex>
                            </Stack>
                          </SimpleGrid>
                        </Stack>
  
                        <Flex justify="space-between" mt="24px">
                          <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            //onClick={() => professionalInformationTab.current.click()}
                          >
                            Next
                          </Button>
                        </Flex>
                    </Flex>
                  </form>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    Last Vitals
                  </Text>
                  <form onSubmit={handleSubmitStep3Info}>
                    <Flex direction="column" w="100%">
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="weight"
                                    placeholder="eg. 74 KG"
                                    label="Weight"
                                    name="weight"
                                    onChange={handleChange}
                                    value={input.weight}
                                />
                                {errors.weight &&
                                  <span className="error-message">
                                      {errors.weight}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="height"
                                    placeholder="eg. 170 CM"
                                    label="Height"
                                    name="height"
                                    onChange={handleChange}
                                    value={input.height}
                                />
                                {errors.height &&
                                  <span className="error-message">
                                      {errors.height}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="blood_presure"
                                    placeholder="eg. 80/120"
                                    label="Blood Presure"
                                    name="blood_presure"
                                    onChange={handleChange}
                                    value={input.blood_presure}
                                />
                                {errors.blood_presure &&
                                  <span className="error-message">
                                      {errors.blood_presure}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="pulse"
                                    placeholder="eg. 80/120"
                                    label="Pulse"
                                    name="pulse"
                                    onChange={handleChange}
                                    value={input.pulse}
                                />
                                {errors.pulse &&
                                  <span className="error-message">
                                      {errors.pulse}
                                  </span>
                                }
                              </Flex>
                            </Stack>
                          </SimpleGrid>
                        </Stack>
  
                        <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                          Chronic Medication
                        </Text>
  
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                              <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                  <label for="aspirin" class="chakra-form__label css-186ooiv">Aspirin<p class="chakra-text css-72mia8"></p></label>
                                  <input
                                      type='date'
                                      mb="0px"
                                      id="aspirin"
                                      name="aspirin"
                                      onChange={handleChange}
                                      value={input.aspirin}
                                      class="chakra-input css-1t1l5ix"
                                  />
                                  {/* {errors.year_of_graduation &&
                                    <span className="error-message">
                                        {errors.year_of_graduation}
                                    </span>
                                  } */}
                                </Flex>
                              </Stack>
  
                              <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                  <label for="nurofen" class="chakra-form__label css-186ooiv">Nurofen<p class="chakra-text css-72mia8"></p></label>
                                  <input
                                      type='date'
                                      mb="0px"
                                      id="nurofen"
                                      name="nurofen"
                                      onChange={handleChange}
                                      value={input.nurofen}
                                      class="chakra-input css-1t1l5ix"
                                  />
                                </Flex>
                              </Stack>
  
                              <Stack direction="column" spacing="20px" margin="20px 0px">
                                <Flex direction="column">
                                  <label for="paracetamol" class="chakra-form__label css-186ooiv">Paracetamol<p class="chakra-text css-72mia8"></p></label>
                                  <input
                                      type='date'
                                      mb="0px"
                                      id="paracetamol"
                                      name="paracetamol"
                                      onChange={handleChange}
                                      value={input.paracetamol}
                                      class="chakra-input css-1t1l5ix"
                                  />
                                </Flex>
                              </Stack>
  
                          </SimpleGrid>
                        </Stack>
  
                        <Flex justify="space-between" mt="24px">
                          <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            //onClick={() => professionalInformationTab.current.click()}
                          >
                            Next
                          </Button>
                        </Flex>
                    </Flex>
                  </form>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    Lab Results
                  </Text>
                  <form onSubmit={handleSubmitStep4Info}>
                    <Flex direction="column" w="100%">
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="wbc"
                                    placeholder="eg. 72 K/PL"
                                    label="WBC"
                                    name="wbc"
                                    onChange={handleChange}
                                    value={input.wbc}
                                />
                                {errors.wbc &&
                                  <span className="error-message">
                                      {errors.wbc}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="neu"
                                    placeholder="eg. 24.45 K/PL"
                                    label="NEU"
                                    name="neu"
                                    onChange={handleChange}
                                    value={input.neu}
                                />
                                {errors.neu &&
                                  <span className="error-message">
                                      {errors.neu}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="lym"
                                    placeholder="eg. 6 K/PL"
                                    label="LYM"
                                    name="lym"
                                    onChange={handleChange}
                                    value={input.lym}
                                />
                                {errors.lym &&
                                  <span className="error-message">
                                      {errors.lym}
                                  </span>
                                }
                              </Flex>
                            </Stack>
  
                            <Stack direction="column" spacing="20px" margin="20px 0px">
                              <Flex direction="column">
                                <InputField
                                    mb="0px"
                                    id="mono"
                                    placeholder="eg. 1.12 K/PL"
                                    label="MONO"
                                    name="mono"
                                    onChange={handleChange}
                                    value={input.mono}
                                />
                                {errors.mono &&
                                  <span className="error-message">
                                      {errors.mono}
                                  </span>
                                }
                              </Flex>
                            </Stack>
                          </SimpleGrid>
                        </Stack>
  
                        <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                          Last Visits
                        </Text>
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="reason_of_visit"
                                placeholder="eg. To get a physical exam and health assessment"
                                label="Reason of visit"
                                name="reason_of_visit"
                                onChange={handleChange}
                                value={input.reason_of_visit}
                            />
                            {errors.reason_of_visit &&
                              <span className="error-message">
                                  {errors.reason_of_visit}
                              </span>
                            }
                          </Flex>
                        </Stack>
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="diagnosis"
                                placeholder="eg. To get a physical exam and health assessment"
                                label="Diagnosis"
                                name="diagnosis"
                                onChange={handleChange}
                                value={input.diagnosis}
                            />
                            {errors.diagnosis &&
                              <span className="error-message">
                                  {errors.diagnosis}
                              </span>
                            }
                          </Flex>
                        </Stack>
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="medications"
                                placeholder="eg. Paracetamol strength, 10% coverage"
                                label="Medications"
                                name="medications"
                                onChange={handleChange}
                                value={input.medications}
                            />
                            {errors.medications &&
                              <span className="error-message">
                                  {errors.medications}
                              </span>
                            }
                          </Flex>
                        </Stack>
  
                        <Flex justify="space-between" mt="24px">
                          <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            //onClick={() => addressTab.current.click()}
                          >
                            Next
                          </Button>
                        </Flex>
                    </Flex>
                  </form>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    All Visits
                  </Text>
  
                  <form onSubmit={handleSubmitStep5Info}>
                    <Flex direction="column" w="100%">
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <label for="passcode" class="chakra-form__label css-186ooiv">Date of last visit<p class="chakra-text css-72mia8"></p></label>
                            <input
                                type='date'
                                mb="0px"
                                id="date_of_last_visit"
                                name="date_of_last_visit"
                                onChange={handleChange}
                                value={input.date_of_last_visit}
                                class="chakra-input css-1t1l5ix"
                            />
                          </Flex>
                        </Stack>
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="details_of_visit"
                                placeholder="eg. W- 74Kg, H- 170CM, BP- 80/120"
                                label="Details of visit"
                                name="details_of_visit"
                                onChange={handleChange}
                                value={input.details_of_visit}
                            />
                            {errors.details_of_visit &&
                              <span className="error-message">
                                  {errors.details_of_visit}
                              </span>
                            }
                          </Flex>
                        </Stack>
  
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="referrals"
                                placeholder="eg. LAB TEST"
                                label="Referrals"
                                name="referrals"
                                onChange={handleChange}
                                value={input.referrals}
                            />
                            {errors.referrals &&
                              <span className="error-message">
                                  {errors.referrals}
                              </span>
                            }
                          </Flex>
                        </Stack>
  
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Flex justify="space-between" mt="24px">
                              <Button
                                  variant="darkBrand"
                                  fontSize="sm"
                                  borderRadius="16px"
                                  w={{ base: '128px', md: '148px' }}
                                  h="46px"
                                  ms="auto"
                                  type='submit'
                                  //onClick={() => redirectToListing()}
                              >
                              Save
                              </Button>
                            </Flex>
                          </SimpleGrid>
                        </Stack>
                    </Flex>
                  </form>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
    );
  }
  
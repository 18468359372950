import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState,useEffect } from 'react';
// Chakra imports
import { jwtDecode } from 'jwt-decode';
import Loading from 'components/Loading';
import axios from "axios";

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const[loading, setLoading] =useState(false);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
            console.log('name',name);
        });
    });
  };

  useEffect(() => {
    axios.interceptors.request.use(function (config) {
      setLoading(true);
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        setLoading(false);
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });

    clearCacheData();
  },[]);

  if(!token || token  === 'undefined'){
    return (
      <ChakraProvider theme={currentTheme}>
        <Loading show={loading}/>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="/*" element={<Navigate to="/auth" replace />} />
        </Routes>
      </ChakraProvider> 
    )
  }else{
    const decodedJwt = jwtDecode(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      return (
        <ChakraProvider theme={currentTheme}>
          <Loading show={loading}/>
          <Routes>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="/*" element={<Navigate to="/auth" replace />} />
          </Routes>
        </ChakraProvider> 
      )
    }
  }

  return (
    <ChakraProvider theme={currentTheme}>
      <Loading show={loading}/>
      <Routes>
        <Route path="auth/*" element={<Navigate to="/admin" replace />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
